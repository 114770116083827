
import { defineComponent } from 'vue';
import List from '@/components/lib/List.vue';
import { store } from '@/store/store';
import { STATE } from '@/constants';
import { ArchiveInterface } from '@/services/archives';
import { formatDate } from '@/utils';
import { ACTION } from '@/store/actions';

export default defineComponent({
    name: 'ArchiveList',
    components: { List },
    computed: {
        archiveLoading(): boolean {
            return store.state.archiveRequestStatus.status === STATE.LOADING;
        },
        archiveReady(): boolean {
            return store.state.archiveRequestStatus.status === STATE.DONE;
        },
        archive(): ArchiveInterface[] {
            return store.state.archives;
        },
        listReady(): boolean {
            return store.getters.archiveListReady;
        },
        hasMatch(): boolean {
            return store.state.schedule.length > 0;
        },
        isDev(): boolean {
            return store.getters.isDev;
        },
        archiveButtonTitle(): string {
            return this.hasMatch? 'Archiver la saison courante': 'Aucun match à archiver';
        },
        archiveError(): boolean {
            return store.state.addArchiveRequestStatus.status === STATE.ERR;
        },
        archiveErrorMsg(): string {
            return store.state.addArchiveRequestStatus.msg;
        }
    },
    methods: {
        formattedDate(date: Date): string {
            return formatDate(date.toISOString());
        },
        createArchive() {
            if(!this.hasMatch) return;
            store.dispatch(ACTION.ADD_ARCHIVE, undefined);
        }
    }
});
