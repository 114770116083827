
import { defineComponent } from 'vue';
import { fetchLogs, LogInterface } from '@/services/logs';
import { REQUEST } from '@/services/documentation';
import { formatDate, timezoneDate } from '@/utils';
import { store } from '@/store/store';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'DatabaseLog',
    computed: {
        logsLoading(): boolean {
            return store.state.userRequestStatus.status === STATE.LOADING
        },
        logs(): LogInterface[] {
            return store.state.databaseLogs
        }
    },
    methods: {
        logDate(idx: number): string {
            const date = timezoneDate(new Date(this.logs[idx].date));
            return formatDate(date.toISOString());
        },
        rowClass(idx: number): string {
            switch (this.logs[idx].actionType) {
                case REQUEST.GET:
                    return 'row__title--get';
                case REQUEST.PUT:
                    return 'row__title--put';
                case REQUEST.DELETE:
                    return 'row__title--delete';
                case REQUEST.POST:
                    return 'row__title--post';
            }
        },
        badgeClass(idx: number): string {
            switch (this.logs[idx].actionType) {
                case REQUEST.GET:
                    return 'log__type--get';
                case REQUEST.PUT:
                    return 'log__type--put';
                case REQUEST.DELETE:
                    return 'log__type--delete';
                case REQUEST.POST:
                    return 'log__type--post';
            }
        }
    }
});
