
import { defineComponent } from 'vue';
import UserCard from '@/components/UserCard.vue';
import {
    approveAccountRequest, denyAccountRequest,
    fetchPendingUsers, fetchUsers, UserInterface
} from '@/services/users';
import { store } from '@/store/store';
import { STATE } from '@/constants';
import { MUTATION } from '@/store/mutations';
import { ACTION } from '@/store/actions';

export default defineComponent({
    name: 'PendingUserList',
    components: { UserCard },
    computed: {
        userLoading(): boolean {
            return store.state.userRequestStatus.status === STATE.LOADING
        },
        userReady(): boolean {
            return store.state.userRequestStatus.status === STATE.DONE
        },
        users(): UserInterface[] {
            return store.state.users
        },
        pendingUsers(): UserInterface[] {
            return store.state.pendingUsers
        },
        pendingUserLoading(): boolean {
            return store.state.pendingUserRequestStatus.status === STATE.LOADING
        },
        pendingUserReady(): boolean {
            return store.state.pendingUserRequestStatus.status === STATE.DONE
        }
    },
    methods: {
        isSelf(userID: string) {
            if(store.state.sessionInfo._id === userID) return true;
        },
        async denyPendingUser(id: string){
            const resp = await denyAccountRequest(id);
            if(resp.success) store.commit(MUTATION.SET_PENDING_USERS, resp.users);
        },
        async confirmPendingUser(id: string){
            const resp = await approveAccountRequest(id);
            if(resp.success) {
                store.commit(MUTATION.SET_PENDING_USERS, resp.users);
                store.dispatch(ACTION.FETCH_USERS, undefined);
            }
            else {
                // TODO set error msg
            }
        }
    }
});
