
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'BackupList',
    data() {
        return {
            selectedTmp: [] as number[]
        }
    },
    props: {
        listSize: {
            type: Number,
            required: true
        },
        selectedItem: {
            type: Array as PropType<Array<number>>,
            required: false,
            default: [] as number[]
        }
    },
    computed: {
        indices(): number[] {
            const x = [];
            x[this.listSize - 1] = this.listSize - 1;
            return x;
        },
        selectedMap(): {[key: number]: boolean} {
            const map = {} as {[key: number]: boolean};
            return this.selectedTmp.map(s => map[s] = true);
        }
    },
    watch:{
        selectedItem() {
            this.selectedTmp = this.selectedItem.slice();
        }
    }
});
