
import { defineComponent } from 'vue';
import UserList from '@/components/UserList.vue';
import PendingUserList from '@/components/PendingUserList.vue';
import DatabaseLog from '@/components/DatabaseLog.vue';
import UserSummary from '@/components/UserSummary.vue';
import ArchiveList from '@/components/ArchiveList.vue';
import BackupList from '@/components/BackupList.vue';
import { ACTION } from '@/store/actions';
import { store } from '@/store/store';
import { STATE } from '@/constants';

export enum TAB {
    USERS = 'users', DB = 'database', REQUESTS = 'user-requests'
}

export default defineComponent({
    name: 'Admin',
    components: {
        UserSummary, UserList, PendingUserList,
        DatabaseLog, BackupList, ArchiveList
    },
    data() {
        return {
            tabSelected: TAB.USERS,
            TAB_TYPES: TAB
        }
    },
    props: {
        tabView: {
            type: String as () => TAB,
            required: false,
            default: TAB.USERS
        }
    },
    mounted() {
        if(store.state.userRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_USERS, undefined);
        }
        if(store.state.pendingUserRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_PENDING_USERS, undefined);
        }
        if(store.state.databaseLogRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_DATABASE_LOGS, undefined);
        }
        if(store.state.backupRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_BACKUPS, undefined);
        }
        if(store.state.archiveRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_ARCHIVES, undefined);
        }
    },
    created() {
        this.tabSelected = this.$router.currentRoute.value.params.tabView as TAB ?? this.tabView;
    },
    methods: {
        toggleView(view: TAB) {
            this.tabSelected = view;
            this.$router.push({name: 'Admin', params: {tabView: view}});
        }
    }
});
