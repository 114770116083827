
import { defineComponent } from 'vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { EditUserInput, UserInterface } from '@/services/users';
import { USER_ROLES } from '@/constants';
import { store } from '@/store/store';
import { TeamInterface } from '@/services/teams';

export default defineComponent({
    name: 'UserCard',
    components: { ConfirmAction, TeamLabel },
    data() {
        return {
            role: USER_ROLES.BASIC,
            teams: [] as string[],
            userRoles: Object.values(USER_ROLES),
            editMode: false
        }
    },
    props: {
        user: {
            type: Object as () => UserInterface,
            required: false,
            default: {
                _id: '',
                email: '',
                name: '',
                teams: [],
                role: USER_ROLES.BASIC
            } as UserInterface
        },
        canDelete: {
            type: Boolean,
            required: false,
            default: true
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        },
        canConfirm: {
            type: Boolean,
            required: false,
            default: false
        },
        canEditRole: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        editUserData(): EditUserInput {
            return {
                _id: this.user._id,
                role: this.role,
                teams: this.teams
            }
        },
        availableTeams(): string[] {
            return store.getters.sortedTeams.filter(t => this.teams.indexOf(t) === -1);
        },
        userImg(): string {
            switch(this.role) {
                case USER_ROLES.BASIC: return '/images/players/man/basketball-player.svg';
                case USER_ROLES.CAPTAIN: return '/images/players/man/king.png';
                case USER_ROLES.ADMIN: return '/images/players/man/dj.png';
                default: return "/images/players/missing_player_pic.png";
            }
        }
    },
    methods: {
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
        editUserEvent() {
            this.$emit('user-edit', this.editUserData);
            this.editMode = false;
        },
        confirmUserEvent() {
            this.$emit('user-confirm', this.editUserData._id);
            this.editMode = false;
        },
        cancelEdits() {
            this.editMode = false;
            this.teams = this.user.teams.slice();
            this.role = this.user.role;
        },
        removeTeam(team: string) {
            this.teams.splice(this.teams.indexOf(team), 1);
        }
    },
    watch: {
        user(user) {
            this.teams = user.teams.slice();
            this.role = user.role;
        }
    },
    mounted() {
        this.teams = this.user.teams.slice();
        this.role = this.user.role;
    }
});
