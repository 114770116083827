
import { defineComponent } from 'vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { store } from '../store/store';
import { TeamInterface } from '../services/teams';
import { STATE, USER_ROLES, USER_ROLES_KEYS, USER_ROLES_PRIO, USER_ROLES_VALUES } from '../constants';
import { UserInterface } from '../services/users';


const NO_TEAMS =  'N/A';

export default defineComponent({
    name: 'UserSummary',
    components: { TeamLabel },
    data() {
        return {
            NO_TEAMS: NO_TEAMS
        }
    },
    props: {
        showAdmin: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        userLoading(): boolean {
            return store.state.userRequestStatus.status === STATE.LOADING
        },
        userReady(): boolean {
            return store.state.userRequestStatus.status === STATE.DONE
        },
        users(): UserInterface[] {
            return store.state.users
        },
        teams(): string[] {
            const teams = store.getters.sortedTeams;
            teams.push(this.NO_TEAMS);
            return teams;
        },
        sortedRoles(): string[] {
            return Object.keys(USER_ROLES).filter(r1 => {
                if (USER_ROLES[r1 as USER_ROLES_KEYS] === USER_ROLES.ADMIN) return this.showAdmin;
                else return true;
            }).sort((r1, r2) => {
                return USER_ROLES_PRIO[r1] - USER_ROLES_PRIO[r2];
            }).map(k => USER_ROLES[k as USER_ROLES_KEYS]);
        },
        userCount(): {[key: string]: {[key: string]: number}} {
            const count: {[key: string]: {[key: string]: number}} = {};
            this.teams.forEach(t => {
                count[t] = {} as {[key: string]: number};
                this.sortedRoles.forEach(r => {
                    count[t][r] = 0;
                });
            })
            this.users.forEach(u => {
                if(!u.teams.length) count[NO_TEAMS][u.role] ++;
                else {
                    u.teams.forEach(t => {
                        if(count[t]) count[t][u.role] ++;
                    });
                }
            });
            return count;
        },
        roleTotal(): {[key: string]: number} {
            const count: {[key: string]: number} = {};
            this.sortedRoles.forEach(r => {
                count[r] = 0;
            });
            this.users.forEach(u => count[u.role] ++);
            return count;
        },
        roleTotalPerTeam(): {[key: string]: number} {
            const count: {[key: string]: number} = {};
            this.teams.forEach(t => {
                count[t] = Object.values(this.userCount[t]).reduce((acc, curr) => {
                    return acc + curr;
                }, 0)
            });
            return count;
        },
        totalUsers(): number {
            return Object.keys(this.roleTotal).reduce((acc, currkey) => {
                return acc + this.roleTotal[currkey];
            }, 0)
        }
    },
    methods: {
        roleDisplayName(role: USER_ROLES_VALUES): string {
            switch(role) {
                case USER_ROLES.ADMIN: return 'Administrateur';
                case USER_ROLES.CAPTAIN: return 'Capitaine';
                case USER_ROLES.BASIC: return 'Membre';
                default: return 'Autre';
            }
        },
        getTeam(teamName: string): TeamInterface{
            return store.getters.teamInfo(teamName);
        },
        roleImg(role: string): string {
            switch(role) {
                case USER_ROLES.BASIC: return '/images/players/man/basketball-player.svg';
                case USER_ROLES.CAPTAIN: return '/images/players/man/king.png';
                case USER_ROLES.ADMIN: return '/images/players/man/dj.png';
                default: return "/images/players/missing_player_pic.png";
            }
        }
    }
})
