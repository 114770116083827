
import { defineComponent } from 'vue';
import UserCard from '@/components/UserCard.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import {
    addUser, AddUserInput,
    editUser, EditUserInput,
    removeUser, UserInterface
} from '@/services/users';
import { store } from '@/store/store';
import { TeamInterface } from '@/services/teams';
import { MUTATION } from '@/store/mutations';
import { USER_ROLES_PRIO, USER_ROLES, USER_ROLES_VALUES, STATE } from '../constants';
import { ACTION } from '@/store/actions';

declare type TSortedUsers = {
    [key: string]: UserInterface[]
}

const NO_TEAMS =  'Aucune équipe';

export default defineComponent({
    name: 'UserList',
    components: { UserCard, TeamLabel },
    data() {
        return {
            addUserName: '',
            addUserEmail: '',
            addUserRole: USER_ROLES.BASIC,
            addUserTeams: [],
            userRoles: Object.values(USER_ROLES),
            userByTeams: false,
            hiddenUserGroup: {} as {[key: string]: boolean},
            NO_TEAMS: NO_TEAMS
        }
    },
    computed: {
        userLoading(): boolean {
            return store.state.userRequestStatus.status === STATE.LOADING
        },
        userReady(): boolean {
            return store.state.userRequestStatus.status === STATE.DONE
        },
        users(): UserInterface[] {
            return store.state.users
        },
        userAddValid(): boolean {
            return true;
        },
        sortedTeams(): string[] {
            return store.getters.sortedTeams;
        },
        sortedUserKeys(): string[] {
            const keys = Object.keys(this.sortedUsers);
            if(this.userByTeams) {
                keys.sort() // sort alphanumerically team names
            }
            else {
                keys.sort((r1, r2) => {
                    return USER_ROLES_PRIO[r1] - USER_ROLES_PRIO[r2];
                });
            }
            return keys;
        },
        sortedUsers(): TSortedUsers {
            const sortedUsers: TSortedUsers = {};
            this.users.forEach(user => {
                if (this.userByTeams) {
                    if(user.teams.length === 0) {
                        const t = NO_TEAMS;
                        if (sortedUsers[t] === undefined) sortedUsers[t] = [user];
                        else sortedUsers[t].push(user);
                    }
                    else {
                        user.teams.forEach(t => {
                            if (sortedUsers[t] === undefined) sortedUsers[t] = [user];
                            else sortedUsers[t].push(user);
                        });
                    }
                }
                else {
                    if (sortedUsers[user.role] === undefined) sortedUsers[user.role] = [user];
                    else sortedUsers[user.role].push(user);
                }
            });
            return sortedUsers;
        },
        addUserImg(): string {
            switch(this.addUserRole) {
                case USER_ROLES.BASIC: return '/images/players/man/basketball-player.svg';
                case USER_ROLES.CAPTAIN: return '/images/players/man/king.png';
                case USER_ROLES.ADMIN: return '/images/players/man/dj.png';
                default: return "/images/players/missing_player_pic.png";
            }
        }
    },
    methods: {
        getTeam(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
        roleDisplayName(role: USER_ROLES_VALUES): string {
            switch(role) {
                case USER_ROLES.ADMIN: return 'Administrateur';
                case USER_ROLES.CAPTAIN: return 'Capitaine';
                case USER_ROLES.BASIC: return 'Membre';
                default: return 'Autre';
            }
        },
        toggleUserGroup(group: string) {
            if(this.hiddenUserGroup[group] === undefined) this.hiddenUserGroup[group] = true;
            else delete this.hiddenUserGroup[group];
        },
        isUserGroupShown(group: string): boolean{
            return this.hiddenUserGroup[group] !== true;
        },
        isSelf(userID: string) {
            if(store.state.sessionInfo._id === userID) return true;
        },
        toggleGroupByTeam() {
            this.userByTeams = !this.userByTeams;
        },
        async deleteUser(id: string){
            const resp = await removeUser(id);
            if(resp.success) {
                store.commit(MUTATION.SET_USERS, resp.users);
            }
        },
        async addUser(){
            if(!this.userAddValid) return;

            const userData: AddUserInput = {
                email: this.addUserEmail,
                name: this.addUserName,
                role: this.addUserRole,
                teams: this.addUserTeams
            }
            const resp = await addUser(userData);
            if(resp.success) {
                store.commit(MUTATION.SET_USERS, resp.users);
            }
        },
        async editUser(userData: EditUserInput){
            const resp = await editUser(userData);
            if(resp.success) {
                store.commit(MUTATION.SET_USERS, resp.users);
                if(this.isSelf(userData._id)) {
                    store.dispatch(ACTION.FETCH_SESSION_INFO, undefined);
                }
            }
        },
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        }
    }
});
