
import { defineComponent } from 'vue';
import List from '@/components/lib/List.vue';
import { store } from '@/store/store';
import { STATE } from '@/constants';
import { BackupInterface } from '@/services/backups';
import { formatDate } from '@/utils';
import { ACTION } from '@/store/actions';
import ConfirmAction from './ConfirmAction.vue';

export default defineComponent({
    name: 'BackupList',
    components: { List, ConfirmAction },
    computed: {
        backupLoading(): boolean {
            return store.state.backupRequestStatus.status === STATE.LOADING;
        },
        backupReady(): boolean {
            return store.state.backupRequestStatus.status === STATE.DONE;
        },
        backups(): BackupInterface[] {
            return store.state.backups;
        },
        listReady(): boolean {
            return store.getters.backupListReady;
        },
        isDev(): boolean {
            return store.getters.isDev;
        },
        backupError(): boolean {
            return store.state.addBackupRequestStatus.status === STATE.ERR;
        },
        backupErrorMsg(): string {
            return store.state.addBackupRequestStatus.msg;
        }
    },
    methods: {
        formattedDate(date: Date): string {
            return formatDate(date.toISOString());
        },
        createBackup() {
            store.dispatch(ACTION.ADD_BACKUP, undefined);
        },
        removeBackup(name: string) {
            store.dispatch(ACTION.REMOVE_BACKUP, name);
        },
        restoreBackup(name: string) {
            store.dispatch(ACTION.RESTORE_BACKUP, name);
        },
        downloadBackup(name: string) {
            store.dispatch(ACTION.GET_BACKUP, name);
        }
    }
});
